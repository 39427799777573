<template>
  <b-card v-if="isIE()">
    <div class="d-flex align-items-center">
      <i class="bi-exclamation-triangle-fill me-3 text-warning"></i>
      <span>
        {{ $t('ie.warning') }}
        <router-link to="/ie">
          {{ $t('ie.readMore') }}
        </router-link>
      </span>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'IEText',
  methods: {
    isIE() {
      const ua = navigator.userAgent
      /* MSIE used to detect old browsers and Trident used to newer ones */
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
    }
  }
}
</script>

<style scoped>
a:hover {
  text-decoration: underline;
}
</style>
