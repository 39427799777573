<template>
  <div>
    <div
      class="d-flex flex-wrap align-items-center justify-content-start gap-sm-1 gap-0 code-input-wrapper"
    >
      <input
        v-for="index in length"
        :key="`code_${index}`"
        :name="`code_${index}`"
        class="code-input flex-grow-1 form-control text-center mx-sm-1 mx-0 my-2"
        value=""
        inputmode="numeric"
        required
        autocomplete="off"
        @input="(e) => inputChanged(e, index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PulsOneTimeCodeInput',
  props: {
    length: Number,
    value: String
  },
  emits: ['input'],
  data() {
    return {
      savedValues: {},
      internalValue: Array(this.length).fill(undefined)
    }
  },
  mounted() {
    this.initInputListeners()
  },
  computed: {},
  methods: {
    isInteger(value) {
      return /^\d+$/.test(value)
    },
    inputChanged(e, index) {
      this.internalValue[index - 1] = e.target.value[0]
      const numberString = this.internalValue
        .map((item) => (item === null ? '0' : item))
        .join('')
      this.$emit('input', numberString)
    },
    initInputListeners() {
      const inputElements = [...document.querySelectorAll('input.code-input')]

      inputElements.forEach((element, index) => {
        // clear the input on click, but save the values to be restored if blurred with no substitute value being entered
        element.addEventListener('click', (e) => {
          this.savedValues[index] = inputElements[index].value
          inputElements[index].value = ''
        })
        element.addEventListener('blur', (e) => {
          if (this.savedValues[index]) {
            inputElements[index].value = this.savedValues[index]
            this.savedValues[index] = null
          }
        })
        element.addEventListener('keydown', (e) => {
          // if a saved value exists for this input, clear it as we no longer want to be able to restore it
          if (this.savedValues[index]) {
            this.savedValues[index] = null
          }
          // If the keycode is backspace & the current field is empty, focus the input "index-1"
          // which will fire the backspace event on the input before this one
          if (e.code === 'Backspace' && e.target.value === '') {
            inputElements[Math.max(0, index - 1)].focus()
          }
        })
        element.addEventListener('input', (e) => {
          const inputValue = e.target.value.trim()
          // If input is not integer, reset input field
          if (!this.isInteger(inputValue)) {
            e.target.value = ''
            return
          }
          const [first, ...rest] = inputValue

          e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
          const lastInputBox = index === inputElements.length - 1
          const didInsertContent = first !== undefined
          if (didInsertContent && !lastInputBox) {
            inputElements[index + 1].focus()
            inputElements[index + 1].value = rest.join('')
            inputElements[index + 1].dispatchEvent(new Event('input'))
          }
        })
      })
    }
  },
  watch: {}
}
</script>

<style>
/* .code-input-wrapper {
  max-height: 60px;
} */
.disabled {
  background-color: var(--kt-input-disabled);
}
.code-input {
  max-height: 34px;
  max-width: 34px;
  font-size: 1.15em !important;
  margin-right: 0.12rem !important;
  margin-left: 0.12rem !important;
}
@media screen and (min-width: 344px) {
  .code-input {
    max-height: 40px;
    max-width: 40px;
    font-size: calc(1.3rem + 0.6vw) !important;
  }
}
@media screen and (min-width: 361px) {
  .code-input {
    max-height: 42px;
    max-width: 42px;
  }
}
@media screen and (min-width: 420px) {
  .code-input {
    max-height: 48px;
    max-width: 48px;
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
}
@media screen and (min-width: 576px) {
  .code-input {
    max-height: 52px;
    max-width: 52px;
    font-size: calc(1.35rem + 1.2vw) !important;
  }
}
@media screen and (min-width: 768px) {
  .code-input {
    max-height: 55px;
    max-width: 55px;
  }
}
@media screen and (min-width: 992px) {
  .code-input {
    max-height: 58px;
    max-width: 58px;
  }
}
@media screen and (min-width: 1400px) {
  .code-input {
    max-height: 60px;
    max-width: 60px;
  }
}
</style>
