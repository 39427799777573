<template>
  <b-container class="pl-0 pl-sm-15 pr-0 pr-sm-15 h-100 pt-10 pb-10">
    <b-row v-if="displayStatus === DS.fullScreenLoading" class="pt-10">
      <span
        class="spinner-border spinner-border-lg center-spinner text-primary"
      ></span>
    </b-row>
    <b-row v-else class="h-100">
      <b-col xl="6" lg="7" md="8" sm="10" xs="10" cols="12" class="m-auto">
        <!-- <PulsOneTimeCodeInput
          v-if="!useLegacyOneTimeCodeInput"
          v-model="code"
          :length="6"
        /> -->
        <b-card class="mb-20">
          <b-form @submit="onSubmit">
            <div class="form-body pl-0">
              <b-row class="mb-3">
                <b-col>
                  <h3 class="ml-sm-3 mb-3">
                    {{ $t('start.enterCode.header.title', { productTitle }) }}
                  </h3>

                  <p
                    v-if="displayStatus === DS.selectOrg"
                    class="ml-sm-3 header-description"
                  >
                    {{ $t('start.enterOrganization.header.description') }}
                  </p>

                  <p
                    v-if="displayStatus === DS.enterEmail"
                    class="ml-sm-3 header-description"
                  >
                    {{
                      $t('start.enterEmail.header.description', {
                        organizationName: organizationFullName
                      })
                    }}
                  </p>

                  <p
                    v-if="displayStatus === DS.confirmRequestCode"
                    class="ml-sm-3 header-description"
                  >
                    {{
                      $t('start.confirmRequestCode.header.description', {
                        productTitle,
                        organizationName: organizationFullName
                      })
                    }}
                  </p>

                  <b-alert
                    v-if="
                      displayStatus === DS.confirmRequestCode &&
                      hasRecentlyRequestedCode
                    "
                    :show="true"
                    varinat="info"
                    class="d-flex align-items-center"
                  >
                    <i class="bi bi-info-circle-fill fs-3 text-info me-3"></i>

                    <span class="text-info">
                      {{ $t('start.confirmRequestCode.header.help') }}
                    </span>
                  </b-alert>

                  <p
                    v-if="displayStatus === DS.enterCode"
                    class="ml-sm-3 header-description"
                  >
                    {{
                      $t('start.enterCode.header.description', {
                        deliveryMedium: $t(
                          `start.enterCode.header.deliveryMedium.${codeDeliveryDetails.DeliveryMedium}`
                        ),
                        to: codeDeliveryDetails.Destination
                      })
                    }}
                  </p>
                </b-col>
              </b-row>

              <b-row v-if="displayStatus === DS.selectOrg">
                <b-col>
                  <span class="bmd-form-group">
                    <div class="d-flex align-items-center">
                      <i class="bi bi-briefcase-fill fs-2 me-3"></i>

                      <PulsFormInput
                        class="flex-grow-1"
                        :placeholder="
                          $t(
                            'start.enterOrganization.form.organization.placeholder'
                          )
                        "
                        v-model="organization"
                        :label="
                          $t(
                            'start.enterOrganization.form.organization.placeholder'
                          )
                        "
                        :is-floating-label="true"
                      />
                    </div>
                  </span>

                  <p v-if="orgNameHasSpaces" class="mt-2 text-danger">
                    {{ $t('start.enterOrganization.header.invalid.spaces') }}
                  </p>

                  <p v-if="orgNameHasUppercaseChars" class="mt-2 text-danger">
                    {{ $t('start.enterOrganization.header.invalid.uppercase') }}
                  </p>

                  <p v-if="orgNameHasSpecialChars" class="mt-2 text-danger">
                    {{
                      $t('start.enterOrganization.header.invalid.specialchars')
                    }}
                  </p>
                </b-col>

                <b-col cols="1"></b-col>
              </b-row>

              <b-row v-if="displayStatus === DS.enterEmail">
                <b-col>
                  <span class="bmd-form-group">
                    <div class="d-flex align-items-center">
                      <i class="bi bi-envelope-fill fs-2 me-3"></i>

                      <PulsFormInput
                        class="flex-grow-1"
                        :disabled="loading"
                        :placeholder="
                          $t('start.enterEmail.form.username.placeholder')
                        "
                        autocomplete="username"
                        v-model="username"
                        spellcheck="false"
                        type="email"
                        :label="
                          $t('start.enterEmail.form.username.placeholder')
                        "
                        :is-floating-label="true"
                      />
                    </div>
                  </span>
                </b-col>

                <b-col cols="1"></b-col>
              </b-row>

              <b-row v-if="displayStatus === DS.enterCode">
                <b-col>
                  <div class="d-flex justify-content-center align-items-center">
                    <!-- <i class="bi bi-lock fs-2 me-3"></i> -->
                    <PulsOneTimeCodeInput
                      v-if="!useLegacyOneTimeCodeInput"
                      v-model="code"
                      :length="6"
                    />
                    <PulsFormInput
                      v-else
                      class="flex-grow-1"
                      id="single-factor-code-text-field"
                      autocomplete="one-time-code"
                      :placeholder="$t('start.enterCode.form.code.placeholder')"
                      v-model="code"
                      :label="$t('start.enterCode.form.code.placeholder')"
                      :is-floating-label="true"
                    />
                  </div>
                </b-col>
              </b-row>

              <div v-if="error">
                <p class="mt-3 ml-3 text-danger">
                  {{ error.message ? error.message : error }}
                </p>
              </div>
            </div>

            <div class="d-flex justify-content-center mt-7">
              <b-button
                v-if="displayStatus === DS.selectOrg"
                variant="primary"
                type="submit"
                class="btn-round"
                :disabled="
                  !organization ||
                  organization.length < 2 ||
                  orgNameHasSpaces ||
                  orgNameHasSpecialChars ||
                  orgNameHasUppercaseChars ||
                  loading
                "
                @click="getOrganizationData()"
              >
                <span
                  class="spinner-border spinner-border-sm me-3"
                  v-if="loading"
                ></span>
                {{ $t('start.button.getOrganizationData.title') }}
              </b-button>

              <b-button
                v-if="displayStatus === DS.enterEmail"
                variant="primary"
                type="submit"
                class="btn-round"
                :disabled="
                  !username || username.length < 2 || !validEmail() || loading
                "
                @click="requestCode()"
              >
                <span
                  class="spinner-border spinner-border-sm me-3"
                  v-if="loading"
                ></span>
                {{ $t('start.button.requestCode.title') }}
              </b-button>

              <b-button
                v-if="displayStatus === DS.confirmRequestCode"
                variant="primary"
                type="submit"
                class="btn-round"
                :disabled="loading"
                @click="requestCode()"
              >
                <span
                  class="spinner-border spinner-border-sm me-3"
                  v-if="loading"
                ></span>
                {{ $t('start.button.requestCode.title') }}
              </b-button>

              <b-button
                v-if="displayStatus === DS.enterCode"
                variant="primary"
                type="submit"
                class="btn-round"
                :disabled="submitDisabled"
                @click="submitCode()"
              >
                <span
                  class="spinner-border spinner-border-sm me-3"
                  v-if="loading"
                ></span>
                {{ $t('start.button.submitCode.title') }}
              </b-button>
            </div>

            <b-row v-if="displayStatus === DS.enterCode">
              <b-col class="mt-2">
                <div class="text-center">
                  <b-link v-b-toggle="'no-code-collapse'">
                    <small class="text-muted">
                      {{ $t('start.enterCode.help.noCodeLinkTitle') }}
                    </small>
                  </b-link>
                </div>

                <b-collapse id="no-code-collapse" class="mt-3">
                  <hr />

                  <span v-if="codeDeliveryDetails.DeliveryMedium === 'EMAIL'">
                    {{
                      $t('start.enterCode.help.noCodeDescriptionEmail', {
                        destination: codeDeliveryDetails.Destination
                      })
                    }}
                  </span>

                  <span v-if="codeDeliveryDetails.DeliveryMedium === 'SMS'">
                    {{
                      $t('start.enterCode.help.noCodeDescriptionSMS', {
                        destination: codeDeliveryDetails.Destination
                      })
                    }}
                  </span>

                  <div class="text-center mt-3">
                    <b-button
                      @click="displayStatus = DS.enterEmail"
                      class="btn-round"
                      variant="secondary"
                    >
                      {{ $t('start.enterCode.help.noCodeStartOverLinkTitle') }}
                    </b-button>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <IEText />
      </b-col>
    </b-row>

    <b-card v-if="debug" class="debug-card">
      <b-card-header><h3>Debug info</h3></b-card-header>

      <b-card-body>
        <table class="table">
          <tr v-for="k in Object.keys(debugData)" :key="k">
            <td>
              <span class="font-weight-bold">{{ k }}</span>
            </td>

            <td>
              <pre> {{ debugData[k] }} </pre>
            </td>
          </tr>
        </table>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
import Amplify from 'aws-amplify'
import { PulsFormInput } from '@puls-solutions/puls-ui-components'
import PulsOneTimeCodeInput from '../components/PulsOneTimeCodeInput'
import IEText from './IEText'
import uuid from 'uuid'

const DisplayStatus = Object.freeze({
  selectOrg: 0,
  enterEmail: 1,
  confirmRequestCode: 2,
  enterCode: 3,
  fullScreenLoading: 4
})

const Isemail = {
  validate: (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const valid = re.test(String(email).toLowerCase())
    return valid
  }
}

export default {
  name: 'Start',
  components: {
    IEText,
    PulsFormInput,
    PulsOneTimeCodeInput
  },
  data() {
    return {
      organizationData: null,
      organizationProvided: false,
      usernameProvided: false,
      organization: null,
      username: null,
      locale: null,
      debug: false,
      code: '',
      hasConfirmedToSendCode: false,
      hasRequestedCode: false,
      loading: false,
      error: null,
      randomPass: null,
      codeDeliveryDetails: { DeliveryMedium: 'EMAIL' },
      displayStatus: DisplayStatus.selectOrg,
      DS: DisplayStatus
    }
  },
  mounted() {
    // Locale
    this.locale = 'en' // default
    if (window.localStorage.selectedLocale) {
      this.locale = window.localStorage.selectedLocale
    }
    if (this.$route.query.l) {
      this.locale = this.$route.query.l
    }
    if (this.locale) {
      this.$i18n.locale = this.locale
    }
    window.localStorage.selectedLocale = this.locale // write back to save

    // User name
    if (this.$route.query.u) {
      this.username = this.$route.query.u
      this.usernameProvided = true
    }

    // Debug flag
    if (this.$route.query.debug) {
      this.debug = true
    }

    const organizationName =
      this.$route.query.o || window.localStorage.organizationName
    // Organization
    if (organizationName) {
      this.organization = organizationName
      window.localStorage.organizationName = this.organization
      if (window.localStorage.orgData) {
        window.localStorage.removeItem('orgData')
      }
    } else if (window.localStorage.organizationName) {
      this.organization = window.localStorage.organizationName
    }
    if (this.organization) {
      this.organizationProvided = true
    }
    if (window.localStorage.orgData) {
      this.organizationData = JSON.parse(window.localStorage.orgData)
      this.configureAmplifyWithOrgData(this.organizationData)
      if (this.username) {
        this.displayStatus = DisplayStatus.confirmRequestCode
      } else {
        this.displayStatus = DisplayStatus.enterEmail
      }
    } else {
      if (this.organization) {
        this.getOrganizationData()
      }
    }
  },
  computed: {
    debugData() {
      // return this.$data
      return {
        hasRecentlyRequestedCode: this.hasRecentlyRequestedCode ? 'yes' : 'no',
        hasAskedUserForSettingsRecently: this.hasAskedUserForSettingsRecently
          ? 'yes'
          : 'no',
        shouldShowSettingsFirstTimeAfterLogin: this
          .shouldShowSettingsFirstTimeAfterLogin
          ? 'yes'
          : 'no',
        lastCodeRequestDate: window.localStorage.lastCodeRequestDate
          ? new Date(parseInt(window.localStorage.lastCodeRequestDate))
          : 'never asked',
        lastAskedForSettingsDate: window.localStorage.hasAskedForSettingsDate
          ? new Date(parseInt(window.localStorage.hasAskedForSettingsDate))
          : 'never asked',
        organizationData: this.organizationData
      }
    },
    orgNameHasSpaces() {
      return this.organization && this.organization.includes(' ')
    },
    orgNameHasSpecialChars() {
      return this.organization && !/^[a-zA-Z0-9 ]+$/i.test(this.organization)
    },
    orgNameHasUppercaseChars() {
      return this.organization && /[A-Z]/.test(this.organization)
    },
    organizationFullName() {
      if (this.organizationData) {
        return this.organizationData.organizationFullName
      } else if (this.organizationName) {
        return this.organizationName
      } else {
        return null
      }
    },
    productTitle() {
      // 'Puls' or what the org has configured
      return this.$productTitle(this)
    },
    hasRecentlyRequestedCode() {
      const lastRequestedDate = window.localStorage.lastCodeRequestDate
      if (lastRequestedDate) {
        const minutesAgo =
          (Date.now() - parseInt(lastRequestedDate)) / 1000 / 60
        return minutesAgo < 5
      } else {
        return false
      }
    },
    hasAskedUserForSettingsRecently() {
      const lastAskedForSettingsDate =
        window.localStorage.hasAskedForSettingsDate
      if (lastAskedForSettingsDate) {
        const minutesAgo =
          (Date.now() - parseInt(lastAskedForSettingsDate)) / 1000 / 60
        return minutesAgo < 29 * 60 * 24 // 29 days
      } else {
        return false
      }
    },
    organizationHasCustomVisibleAttributes() {
      return (
        this.organizationData &&
        this.organizationData.attributes &&
        this.organizationData.attributes.filter((a) => {
          return a.data && a.data.enabled
        }).length
      )
    },
    shouldShowSettingsFirstTimeAfterLogin() {
      if (
        this.organizationData &&
        this.organizationData.orgSettings &&
        this.organizationData.orgSettings.organizationGeneralConfig
      ) {
        return this.organizationData.orgSettings.organizationGeneralConfig
          .shouldShowSettingsFirstTimeAfterLogin
      } else {
        return false
      }
    },
    submitDisabled() {
      return !this.code || this.code.length < 6 || this.loading
    },
    useLegacyOneTimeCodeInput() {
      return false
    }
  },
  watch: {
    organizationData() {
      this.handleIdentityProviders()
    },
    code(newCode) {
      this.code = this.lowercaseNoSpaces(newCode)
    },
    username(newUsername) {
      if (Isemail.validate(newUsername)) {
        // only lower case if username is an email address
        this.username = this.lowercaseNoSpaces(newUsername)
      }
    }
  },
  methods: {
    handleIdentityProviders() {
      // Redirect to sso if org has identity providers configured. Default is no identity providers.
      if (this.organizationData?.identityFederation?.identityProviders.length) {
        this.$router.push('/sso')
      }
    },
    productName() {
      // can be null
      return this.$productName(this)
    },
    setHasAskedUserForSettingsRecently() {
      window.localStorage.hasAskedForSettingsDate = Date.now()
    },
    onSubmit(e) {
      e.preventDefault()
    },
    getOrganizationData(e) {
      this.displayStatus = DisplayStatus.fullScreenLoading
      if (e) {
        e.preventDefault()
      }
      this.loading = true
      this.error = null
      Amplify.API.get('PublicAPI', '/organizations/' + this.organization, {})
        .then((orgResult) => {
          this.loading = false
          window.localStorage.organizationName = this.organization
          window.localStorage.orgData = JSON.stringify(orgResult)
          this.organizationProvided = true
          this.organizationData = orgResult
          this.configureAmplifyWithOrgData(this.organizationData)
          if (this.username) {
            this.displayStatus = DisplayStatus.confirmRequestCode
          } else {
            this.displayStatus = DisplayStatus.enterEmail
          }
          this.initTheme(orgResult)
          // if (this.username) { // in case user name is already provided, we request code right away
          // this.requestCode()
          // }
        })
        .catch((error) => {
          this.loading = false
          if (error.response.status === 404) {
            this.error = {
              message: this.$t('error.OrganizationNotFoundException')
            }
            this.displayStatus = DisplayStatus.selectOrg
          } else {
            this.error = error
          }
          this.organizationProvided = false
        })
    },
    initTheme(orgResult) {
      if (orgResult.variant === 'feelgood') {
        window.dispatchEvent(
          new CustomEvent('theme-changed', {
            detail: {
              theme: 'feelgood'
            }
          })
        )
      } else if (window.matchMedia) {
        const theme = window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
        window.dispatchEvent(
          new CustomEvent('theme-changed', {
            detail: {
              theme
            }
          })
        )
      }
    },
    configureAmplifyWithOrgData(orgData) {
      const config = {
        Auth: {
          identityPoolId: orgData.identityPoolId,
          region: 'eu-west-1',
          userPoolId: orgData.userPoolId,
          userPoolWebClientId: orgData.userPoolWebClientId,
          clientMetadata: {
            organizationName: this.organization,
            organizationFullName: this.organizationFullName,
            locale: this.locale
          }
        }
      }
      if (orgData.orgSettings && orgData.orgSettings.customProductName) {
        config.Auth.clientMetadata.productName =
          orgData.orgSettings.customProductName
      }
      Amplify.configure(config)
    },
    createUserIfPossible(tries) {
      Amplify.API.post(
        'PublicAPI',
        '/check/organizations/' + this.organization,
        { body: { username: this.username } }
      )
        .then((response) => {
          this.requestCode(null, tries + 1) // second time
        })
        .catch((error) => {
          this.loading = false
          // this.loadingHideEverything = false
          if (error.response.status === 404) {
            this.error = {
              message: this.$t('error.UserNotFoundException', {
                username: this.username
              })
            }
            this.username = ''
            this.usernameProvided = false
          } else {
            this.error = error
          }
        })
    },
    requestCode(e, tries = 0) {
      if (e) {
        e.preventDefault()
      }
      this.loading = true
      this.error = null
      window.localStorage.lastCodeRequestDate = Date.now()
      Amplify.Auth.forgotPassword(this.username)
        .then((response) => {
          this.loading = false
          this.codeDeliveryDetails = response.CodeDeliveryDetails
          this.hasRequestedCode = true
          this.displayStatus = DisplayStatus.enterCode
          // this.loadingHideEverything = false
        })
        .catch((err) => {
          this.loading = false
          if (tries < 2) {
            if (err.code === 'ResourceNotFoundException') {
              this.error = err.message
            } else if (err.code === 'UserNotFoundException') {
              this.createUserIfPossible(tries)
              // }
              // else if (err.code === 'LimitExceededException') {
            } else {
              if (typeof err === 'string') {
                this.error = err
              } else {
                this.error = this.$t(`error.${err.code}`)
              }
              this.usernameProvided = false
              this.username = ''
              this.displayStatus = DisplayStatus.enterEmail
            }
          } else {
            // If username doesnt exists in cognito but user's email exists in cognito, we might get stuck in loop
            this.error = this.$t(`error.EternalLoopError`)
          }
        })
    },
    submitCode(e) {
      if (e) {
        e.preventDefault()
      }
      this.loading = true
      this.displayStatus = DisplayStatus.fullScreenLoading
      this.randomPass = uuid()
      this.error = null
      Amplify.Auth.forgotPasswordSubmit(
        this.username,
        this.code,
        this.randomPass
      )
        .then(() => {
          this.loading = false
          this.loginUser()
        })
        .catch((err) => {
          this.loading = false
          if (typeof err === 'string') {
            this.error = err
          } else {
            this.error = this.$t(`error.${err.code}`)
          }
          this.randomPass = null
          this.displayStatus = DisplayStatus.enterCode
        })
    },
    loginUser(e) {
      if (e) {
        e.preventDefault()
      }
      // this.loadingHideEverything = true
      Amplify.Auth.signIn(this.username, this.randomPass)
        .then((user) => {
          if (user.challengeName) {
            // this.loadingHideEverything = false
            this.error = { message: this.$t('error.NotAuthorizedException') }
            this.displayStatus = DisplayStatus.enterEmail
          } else {
            this.displayStatus = DisplayStatus.fullScreenLoading
            this.$store.commit('setLoggedInUser', user)
            this.$store.commit('setHasToken', true)
            if (
              this.hasAskedUserForSettingsRecently ||
              !this.shouldShowSettingsFirstTimeAfterLogin ||
              !this.organizationHasCustomVisibleAttributes
            ) {
              this.$router.push('/')
            } else {
              this.setHasAskedUserForSettingsRecently()
              this.$router.push({
                name: 'User',
                params: { comesFromStart: true }
              })
            }
          }
        })
        .catch((err) => {
          // this.loadingHideEverything = false
          if (typeof err === 'string') {
            this.error = err
          } else {
            this.error = this.$t(`error.${err.code}`)
          }
          this.displayStatus = DisplayStatus.enterCode
        })
    },
    lowercaseNoSpaces(value) {
      return value
        .toLowerCase()
        .replace(' ', '')
        .replace('ö', 'o')
        .replace('ä', 'a')
        .replace('å', 'a')
    },
    validEmail() {
      return Isemail.validate(this.username)
    }
  }
}
</script>

<style lang="scss" scoped>
.center-spinner {
  margin: 0 auto !important;
  float: none !important;
}

::-webkit-input-placeholder,
::-moz-placeholder {
  margin-left: 10px !important;
}

@media (max-width: 340px) {
  .form-body {
    margin-left: 0px;
    padding-left: 0px;
  }
}

.debug-card {
  height: 800px;
  overflow: scroll;
}
</style>
